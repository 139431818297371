<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-card>
        <template #header>
          <h5>Importer Configuration</h5>
          <small>Custom configuration</small>
        </template>
        <b-row class='mb-2'>
          <b-col class='d-flex justify-content-end' cols='12'>
            <b-button @click='saveConfig' type='button' :disabled='$v.$invalid || saving' id='btn_submit'
              variant='primary'>
              <b-spinner label='Loading...' variant='light' small v-if='saving'></b-spinner>
              <span v-else>Save configuration</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols='3' class="mt-2">
            <label>Name</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group :invalid-feedback="handleError(`data.importer_name`)">
              <b-form-input id='importer-name' type='text' v-model='data.importer_name'
                :state="isValid('data.importer_name')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols='3' class="mt-2">
            <label>BigQuery Dataset Name</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group>
              <b-form-input id='bigquery_dataset_name' type='text' v-model='data.bigquery_dataset_name'
                :disabled="data.disabled">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols='3' class="mt-2">
            <label>Filepath</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group :invalid-feedback="handleError(`data.filepath`)">
              <b-form-input id='filepath' type='text' v-model='data.filepath' :state="isValid('data.filepath')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols='3' class="mt-2">
            <label>Priority</label>
          </b-col>
          <b-col cols='9'>
            <b-form-group>
              <b-form-input id='priority' type='number' value="1000" v-model='data.priority'>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card>
          <template #header>
            <label>File Schema</label>
          </template>
          <b-row cols="12" class="ml-1">
            <b-col cols='3' class="mb-2">
              <label>Field name</label>
            </b-col>
            <b-col cols='3' class="mb-2">
              <label>Type</label>
            </b-col>
            <b-col cols='3' class="mb-2">
              <label>Mode</label>
            </b-col>
            <b-col cols='3' class="mb-2">
              <label>Description</label>
            </b-col>
          </b-row>
          <b-row v-for='(item, index) in $v.data.file_type_schema.$each.$iter' :key="'field-' + index">
            <b-col cols="3">
              <b-form-group :state="!item.field_name.$invalid"
                :invalid-feedback="handleNameError(item.$model.field_name)">
                <b-form-input id='item-field_name' type='text' :disabled="item.$model.disabled.field_name"
                  v-model='item.$model.field_name'>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" class="mt-1">
              <b-form-select v-model="item.$model.type" :options="schemaTypesOptions" :disabled="item.$model.disabled.type" />
            </b-col>
            <b-col cols="3" class="mt-1">
              <b-form-select v-model="item.$model.mode" :options="schemaModesOptions" />
            </b-col>
            <b-col cols="3" class="mt-1">
              <b-form-input type="text" v-model="item.$model.description" />
            </b-col>
          </b-row>
          <b-row class='mb-2 mt-1'>
            <b-col class='d-flex justify-content-end' cols='12'>
              <b-button id="add-new-field" type="button" variant="primary" @click='addNewField'>Add new field</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mt-4">
          <template #header>
            <label>Custom Validator</label>
          </template>
          <b-row>
            <b-col cols='6'>
              <TextareaAutosize class='text-area' placeholder='Configuration' ref='myTextarea'
                v-model='data.custom_validator' :min-height='200' @input="handleKeydown" />
            </b-col>
            <b-col cols='6'>
              <b-card class='mb-4 pretty-text-area'>
                <pre>{{ data.custom_validator | pretty }}</pre>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="ml-1 mt-1 mb-2">
            <b-col>
              <b-button @click='prettyJson()'>JSON pretty</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="mt-4">
          <b-col>
            <b-row class='mb-2'>
              <b-col cols='12' class='mb-2'>
                <h5>Metadata:</h5>
              </b-col>
            </b-row>
            <b-row class='mb-4 ml-2'>
              <b-col cols='3'>
                <label>Created At:</label>
              </b-col>
              <b-col cols='9'>
                <span>{{ normalizeExecutionDetailDate(data.created_at) }}</span>
              </b-col>
            </b-row>
            <b-row class='mb-4 ml-2'>
              <b-col cols='3'>
                <label>Updated At:</label>
              </b-col>
              <b-col cols='9'>
                <span>{{ normalizeExecutionDetailDate(data.updated_at) }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import VueTextareaAutosize from 'vue-textarea-autosize';
import Vue from 'vue';

import * as R from 'ramda';
import moment from 'moment/moment';
import { debounce } from 'lodash';
import errorHandler from '@/mixins/errorHandler';
import { required, numeric } from 'vuelidate/lib/validators';
import schemaTypes from './schemas/schemaTypes.json';
import schemaModes from './schemas/schemaModes.json';
import defaultSchemaFields from './schemas/defaultSchemaFields.json';

Vue.use(VueTextareaAutosize);

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'SFTPGenericImporterConfigs',
  mixins: [ errorHandler ],
  props: {
    configs: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      data: {
        importer_name: null,
        bigquery_dataset_name: null,
        priority: null,
        file_type_schema: [],
      },
      schemaTypesOptions: schemaTypes,
      schemaModesOptions: schemaModes,
    };
  },
  validations() {
    return {
      data: {
        importer_name: { required },
        filepath: { required },
        priority: { required, numeric },
        file_type_schema: {
          $each: {
            field_name: {
              required,
              isUnique(value) {
                if (value === '') {
                  return true;
                }
                return this.data.file_type_schema.filter(o => o.field_name === value).length <= 1;
              },
            },
            type: { required },
          },
        },
      },
    };
  },
  beforeMount() {
    this.loadConfigs();
  },
  filters: {
    pretty(value) {
      try {
        return JSON.stringify(JSON.parse(value), null, 2);
      } catch (e) {
        return 'Invalid JSON Input!!';
      }
    },
  },
  created() {
    this.prettyJson = debounce(this.prettyJson, 1000, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    async saveConfig() {
      this.saving = true;
      if (this.$v.data.$invalid) {
        this.$noty.error('The configuration for importer is invalid.');
      }
      try {
        const data = {
          name: this.data.importer_name,
          bigquery_dataset_name: this.data.bigquery_dataset_name,
          filepath: this.data.filepath,
          priority: this.data.priority,
          file_type_schema: this.data.file_type_schema.map(item => R.omit([ 'disabled' ], item)),
          custom_validator: this.mapCustomValidator(this.data.custom_validator),
        };
        if (this.data.id) {
          const res = await this.$store.dispatch('Sftp/GenericImporter/updateGenericImporter', { id: this.data.id, data })
            .then(result => result.data);
          this.data = this.mapProperties(res);
        } else {
          if (isNullOrEmpty(data.bigquery_dataset_name)) {
            data.bigquery_dataset_name = `${this.data.importer_name.toLowerCase().replace(/ /g, '_')}`;
          }
          const id = await this.$store.dispatch('Sftp/GenericImporter/createGenericImporter', { data }).then(result => result.data);
          window.location.assign(`#/sftp/importers/${id}`);
        }

        this.$noty.success('Import Configurations saved');
      } catch (error) {
        this.$noty.error(`Unable to save settings: \n${error}`);
      } finally {
        this.saving = false;
      }
    },
    loadConfigs() {
      if (isNullOrEmpty(this.configs)) {
        this.data = {
          importer_name: null,
          bigquery_dataset_name: null,
          filepath: null,
          priority: 1000,
          file_type_schema: [],
          custom_validator: JSON.stringify({}, null, 2),
        };
        this.addDefaultFields();
        return;
      }

      if (isNullOrEmpty(R.path([ 'file_type_schema' ], this.configs))) {
        this.data = {
          importer_name: this.configs.name,
          filepath: this.configs.filepath,
          priority: this.configs.priority,
          bigquery_dataset_name: this.configs.bigquery_dataset_name,
          file_type_schema: [],
          custom_validator: !isNullOrEmpty(this.configs.custom_validator)
            ? JSON.stringify(this.configs.custom_validator, null, 2) : JSON.stringify({}, null, 2),
        };
        this.addDefaultFields();
        return;
      }

      this.data = {
        id: this.configs.id,
        importer_name: this.configs.name,
        bigquery_dataset_name: this.configs.bigquery_dataset_name,
        filepath: this.configs.filepath,
        priority: this.configs.priority,
        disabled: this.configs.disabled,
        file_type_schema: this.configs.file_type_schema,
        created_at: this.configs.created_at,
        updated_at: this.configs.updated_at,
        custom_validator: !isNullOrEmpty(this.configs.custom_validator)
          ? JSON.stringify(this.configs.custom_validator, null, 2) : JSON.stringify({}, null, 2),
      };
    },
    addDefaultFields() {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const field of defaultSchemaFields) {
        field.disabled = {
          field_name: true,
          type: false,
        };
        this.data.file_type_schema.push(field);
      }
    },
    addNewField() {
      const field = {
        field_name: '',
        type: 'STRING',
        description: '',
        mode: 'NULLABLE',
        disabled: {
          field_name: false,
          type: false,
        },
      };
      this.data.file_type_schema.push(field);
    },
    handleNameError(value) {
      if (isNullOrEmpty(value)) {
        return 'File Type Name is required';
      }
      return 'Duplicated entry';
    },
    mapProperties(data) {
      data.importer_name = data.name;
      data = R.omit([ 'name' ], data);
      data.custom_validator = !isNullOrEmpty(data.custom_validator)
        ? JSON.stringify(data.custom_validator, null, 2) : JSON.stringify({}, null, 2);
      return this.setPropertiesDisabled(data);
    },
    setPropertiesDisabled(data) {
      if (!isNullOrEmpty(data)) {
        data.disabled = true;
        if (!isNullOrEmpty(data.file_type_schema) && Array.isArray(data.file_type_schema)) {
          data.file_type_schema = data.file_type_schema.map(item => ({ ...item, disabled: { field_name: true, type: true } }));
        }
      }
      return data;
    },
    normalizeExecutionDetailDate(date) {
      if (isNullOrEmpty(date)) {
        return 'Not saved yet';
      }
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    prettyJson() {
      try {
        if (isNullOrEmpty(this.data.custom_validator)) {
          this.data.custom_validator = JSON.stringify({}, null, 2);
        } else {
          this.data.custom_validator = JSON.stringify(JSON.parse(this.data.custom_validator), null, 2);
        }
      } catch (e) {
        this.data.custom_validator = this.data.custom_validator;
      }
    },
    handleKeydown() {
      this.prettyJson();
    },
    mapCustomValidator(customValidator) {
      if (isNullOrEmpty(customValidator)) {
        return null;
      }

      return JSON.parse(customValidator);
    },
  },
};
</script>

<style>
#item-field_name {
  height: 3em;
}

.text-area {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1% 1% 1% 1%;
}

.input-box {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.pretty-text-area {
  font-family: avenir, Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #333;
}
</style>
